import './App.css';
import Release from './Release'

function App() {
  return (
    <div className="App">
      <Release></Release>
    </div>
  );
}

export default App;
